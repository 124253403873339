import FileSaver from 'file-saver';
import Cookie from 'js-cookie';
import axios from '~/utilities/axios';
import axiosV2 from '~/utilities/axiosV2';
import { parseDateForServer } from '~/utilities/dateParser';
import {
  investorsApi,
  importInvestorApi,
  labelsApi,
  getArgumentsForApi,
  securitizeIdIssuerApi,
  getArgumentsForExport,
} from '~/utilities/mainApiClient';
import { reverseSync } from '~/utilities/external-api/apis/bulkServiceCpApi';
import { i18n } from '~/i18n';
import { getHistoryPayouts } from '@/utilities/external-api/apis/payoutCpApi';
import {
  getTokenPreferences,
  createUpdateTokenPreferences,
  getFundraiseInvestors,
  getArgumentsForFundraise,
} from '../../../utilities/external-api/apis/investorCpApi';
import { editTokenIssuance } from '../../../utilities/external-api/apis/issuanceCpApi';
import {
  getInvestorTokenTransactionsHistory,
  updateTokenTransactionById,
} from '@/utilities/external-api/apis/tokenTransactionCpApi';

const basePath = '/api/v2/';
const {
  addInvestor,
  addTokenWallet,
  createInvestorInvestment,
  createPayout,
  createTokenIssuance,
  deleteTokenIssuanceById,
  deleteTokenWallet,
  editTokenWallet,
  getHolderAffiliateRecords,
  getInvestorInvestment,
  getInvestorKYCLastReportById,
  getPartners,
  getTokensAndWallets,
  moveBonus,
  performManualKycCheck,
  remove2fa,
  resetPasswords,
  sendAccreditationEmail,
  sendAccreditationEmails,
  sendInviteSecIdEmails,
  sendInviteEmail,
  sendInviteEmails,
  sendResetPasswordEmail,
  transferTreasuryToTokenWallet,
  updateInvestorInvestmentByUserId,
  updateInvestorTokensAssigned,
  sendFundingInstructionsEmail,
  sendFundingInstructionsEmails,
  sendAgreementEmail,
  sendDocuSignEmail,
  getLegalSigners,
  deleteLegalSigner,
  createLegalSigner,
  getLegalSignerById,
  updateLegalSignerById,
  getLegalSignerDocumentById,
  deleteLegalSignerDocumentById,
  createLegalSignerDocument,
} = investorsApi;

const { importInvestorsFromCsv } = importInvestorApi;
const { getIssuerLabels, labelsBatchOperation, deleteIssuerLabels } = labelsApi;
const { createOrUpdateSecIdInvestor } = securitizeIdIssuerApi;

const investorArguments = {
  issuerId: undefined,
  view: undefined,
  q: undefined,
  fullName: undefined,
  email: undefined,
  countryCode: undefined,
  countryCodes: undefined,
  stateCode: undefined,
  investorType: undefined,
  investorTypes: undefined,
  walletOwnerType: undefined,
  walletOwnerName: undefined,
  kycStatus: undefined,
  kycStatuses: undefined,
  accreditedStatus: undefined,
  fromCreatedAt: undefined,
  toCreatedAt: undefined,
  fromTotalTokens: undefined,
  toTotalTokens: undefined,
  fromAmountPledged: undefined,
  toAmountPledged: undefined,
  fromAmountFunded: undefined,
  toAmountFunded: undefined,
  subscriptionAgreementStatus: undefined,
  subscriptionAgreementStatuses: undefined,
  tokenStatus: undefined,
  registrationSource: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
  roundId: undefined,
  investorStatus: undefined,
  investorStatuses: undefined,
  birthDate: undefined,
  walletRegistrationStatus: undefined,
  tokenWalletAddress: undefined,
  fromPercentage: undefined,
  toPercentage: undefined,
  isLocked: undefined,
  documentNumber: undefined,
  documentType: undefined,
  holderTypeView: undefined,
  tokenId: undefined,
  labels: undefined,
  hasSecuritizeId: undefined,
  custodianId: undefined,
  taxId: undefined,
  taxCountryCode: undefined,
  fromAssignedTokens: undefined,
  toAssignedTokens: undefined,
};
const holderAffiliateRecordsArguments = {
  issuerId: undefined,
  tokenId: undefined,
  userId: undefined,
  page: undefined,
  limit: undefined,
  orderField: undefined,
  orderDirection: undefined,
};

function getInvestorsV3(
  issuerId,
  view,
  q,
  fullName,
  email,
  countryCode,
  countryCodes,
  stateCode,
  investorType,
  investorTypes,
  walletOwnerType,
  walletOwnerName,
  kycStatus,
  kycStatuses,
  accreditedStatus,
  fromCreatedAt,
  toCreatedAt,
  fromTotalTokens,
  toTotalTokens,
  fromAmountPledged,
  toAmountPledged,
  fromAmountFunded,
  toAmountFunded,
  subscriptionAgreementStatus,
  subscriptionAgreementStatuses,
  tokenStatus,
  registrationSource,
  page,
  limit,
  orderField,
  orderDirection,
  roundId,
  investorStatus,
  investorStatuses,
  birthDate,
  walletRegistrationStatus,
  tokenWalletAddress,
  fromPercentage,
  toPercentage,
  isLocked,
  documentNumber,
  documentType,
  holderTypeView,
  tokenId,
  labels,
  hasSecuritizeId,
  custodianId,
  taxId,
  taxCountryCode,
) {
  return axiosV2.get(`/isr/api/v1/issuers/${issuerId}/investors`, {
    params: {
      view,
      q,
      fullName,
      email,
      countryCode,
      countryCodes,
      stateCode,
      investorType,
      investorTypes,
      walletOwnerType,
      walletOwnerName,
      kycStatus,
      kycStatuses,
      accreditedStatus,
      fromCreatedAt,
      toCreatedAt,
      fromTotalTokens,
      toTotalTokens,
      fromAmountPledged,
      toAmountPledged,
      fromAmountFunded,
      toAmountFunded,
      subscriptionAgreementStatus,
      subscriptionAgreementStatuses,
      tokenStatus,
      registrationSource,
      page,
      limit,
      orderField,
      orderDirection,
      roundId,
      investorStatus,
      investorStatuses,
      birthDate,
      walletRegistrationStatus,
      tokenWalletAddress,
      fromPercentage,
      toPercentage,
      isLocked,
      documentNumber,
      documentType,
      holderTypeView,
      tokenId,
      labels,
      hasSecuritizeId,
      custodianId,
      taxId,
      taxCountryCode,
    },
  });
}

export default {
  labelsBatchOperation({ commit }, { issuerId, body }) {
    return labelsBatchOperation(issuerId, body)
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.labelsBatchOperation'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Investors - labels update error:', err);
      });
  },

  getIssuerLabels({ commit }, { issuerId, q, page, limit }) {
    return getIssuerLabels(issuerId, q, page, limit)
      .then(({ data }) => {
        commit('SET_ORIGINAL_LABELS_LIST', data.data);
        commit('SET_LABELS_LIST', data.data);
        return data.data;
      })
      .catch((err) => {
        this.$log.error('Investors - labels get error:', err);
      });
  },
  deleteIssuerLabels({ commit }, { issuerId, label }) {
    return deleteIssuerLabels(issuerId, label)
      .then(() => {
        commit('DELETE_LABEL_FROM_LIST', label);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.issuerLabel.deleteSuccess', [label]),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Issuer Labels - label delete error:', err);
      });
  },
  sendInviteEmail({ commit }, { issuerId, userId, body }) {
    return sendInviteEmail(issuerId, userId, body)
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.sendInviteEmail'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Investors - Invite email get error:', err);
      });
  },

  sendTreasuryToWallet(
    { commit },
    { issuerId, userId, tokenWalletId, walletName, amount },
  ) {
    return transferTreasuryToTokenWallet(issuerId, userId, tokenWalletId, {
      data: { amount },
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.sendTreasuryToWallet', [walletName]),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors - Treasury transfer failed.', err);
        throw err;
      });
  },

  createPayout({ commit }, { issuerId, userId, body }) {
    return createPayout(issuerId, userId, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.createPayout'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Payout create error:', err);
      });
  },

  getHistoryPayouts(ctx, params) {
    const {
      params: { investorId, issuerId, tokenId, page, limit },
    } = params;
    return getHistoryPayouts({
      investorId,
      issuerId,
      tokenId,
      page: Number(page),
      limit,
    })
      .then(res => res)
      .catch((err) => {
        this.$log.error('Payout get error:', err);
      });
  },

  getInvestorTransactionHistory(ctx, params) {
    const {
      params: { issuerId, tokenId, userId, page, limit },
    } = params;
    return getInvestorTokenTransactionsHistory(
      issuerId,
      tokenId,
      userId,
      page,
      limit,
    )
      .then(res => res)
      .catch((err) => {
        this.$log.error('Transaction History get error:', err);
      });
  },

  sendResetPasswordEmail({ commit }, { issuerId, userId }) {
    return sendResetPasswordEmail(issuerId, userId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        commit('global/CALL_TOASTER', '', { root: true });
      })
      .catch((err) => {
        this.$log.error('Investors - Reset password email get error:', err);
      });
  },
  remove2Fa({ commit, dispatch }, { issuerId, userId }) {
    return remove2fa(issuerId, userId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.remove2Fa'),
          { root: true },
        );
        dispatch('getInvestorDetailsGeneral', { issuerId, userId });
      })
      .catch((err) => {
        this.$log.error('Investors - Disable 2Fa get error:', err);
      });
  },
  syncSecuritizeIdInvestor(
    { commit },
    { issuerId, securitizeIdProfileId, forceUpdate },
  ) {
    const showErrors = true;
    const preventMissMatchEmail = true;
    const activateByOperator = true;
    return createOrUpdateSecIdInvestor(issuerId, {
      securitizeIdProfileId,
      forceUpdate,
      showErrors,
      preventMissMatchEmail,
      activateByOperator,
    })
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.syncSecuritizeIdInvestor'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Investors - Sync Securitize ID error:', err);
        throw err;
      });
  },
  changeDetailsEditableBlock({ commit }, blockName) {
    commit('CHANGE_DETAILS_EDITABLE_BLOCK', blockName);
  },

  getInvestorForAutoComplete(ctx, { params }) {
    const args = getArgumentsForApi(investorArguments, params);
    return getInvestorsV3(...args).catch((err) => {
      this.$log.error('Investors get error:', err);
    });
  },
  getInvestorsList(ctx, { params }) {
    const args = getArgumentsForApi(investorArguments, params);
    return getInvestorsV3(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Investors get error:', err);
        return err;
      });
  },
  getFundraiseInvestorsList(ctx, { params }) {
    const args = getArgumentsForFundraise(investorArguments, params);
    const { issuerId, tokenId } = args;
    return getFundraiseInvestors(issuerId, tokenId, args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Investors get error:', err);
        return err;
      });
  },
  addInvestor({ commit }, { body, investorsType }) {
    const { issuerId } = investorsType;
    return addInvestor(issuerId, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.addInvestor'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors add error:', err);
      });
  },

  deleteInvestorByUserId({ commit }, { issuerId, userId }) {
    return axiosV2
        .delete(`/isr/api/v1/issuers/${issuerId}/investors/${userId}`)
        .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.deleteInvestorByUserId'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors delete error:', err);
        throw err;
      });
  },

  exportList(ctx, { fileName, exportData }) {
    const args = getArgumentsForExport(investorArguments, exportData);
    const { issuerId } = exportData;
    return axiosV2
      .get(`/isr/api/v1/issuers/${issuerId}/investors`, {
        params: args,
        headers: { accept: 'text/csv' },
      })
      .then(({ data }) => {
        const blob = new Blob(data);
        FileSaver.saveAs(blob, `${fileName}.csv`);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },
  async importList({ commit }, { issuerId, body }) {
    const useNewGw = process.env.VUE_APP_ENABLE_ISR_GW_IMPORT_INVESTORS === 'true';
    if (useNewGw) {
      const url = `/isr/api/v1/issuers/${issuerId}/import-investor`;
      return axiosV2
        .post(url, body)
        .then((res) => {
          commit(
            'global/CALL_TOASTER',
            i18n.t('store.toaster.investors.importList'),
            { root: true },
          );
          return res;
        })
        .catch((err) => {
          this.$log.error('Import investors list error:', err);
          throw err;
        });
    }
    return importInvestorsFromCsv(issuerId, false, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.importList'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Import investors list error:', err);
        throw err;
      });
  },

  async getSubscriptionAgreementStatusLogs({ commit }, { params }) {
    const {
      issuerId,
      investorId,
      tokenId,
      investmentRequestId,
      roundId: investmentRoundId,
    } = params;
    const url = `${basePath}issuers/${issuerId}/investors/${investorId}/investment/subscription-agreement-status-logs`;
    try {
      const { data } = await axios.get(url, {
        params: {
          'token-id': tokenId,
          'investment-request-id': investmentRequestId,
          'investment-round-id': investmentRoundId,
        },
      });
      commit('SET_SUBSCRIPTION_AGREEMENT_STATUS_LOGS', data);
      return data;
    } catch (err) {
      this.$log.error('get subscription agreement status logs get error:', err);
      throw err;
    }
  },

  async getInvestorDetailsGeneral({ commit }, { issuerId, userId }) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/details`;
    try {
      const { data } = await axios.get(url);
      commit('SET_INVESTOR_DETAILS_GENERAL', data);
      return data;
    } catch (err) {
      this.$log.error('Investors details general get error:', err);
      throw err;
    }
  },
  getInvestorDetailsTokens({ commit }, { issuerId, tokenId, userId }) {
    return getTokensAndWallets(issuerId, userId, tokenId)
      .then(({ data }) => {
        commit('SET_INVESTOR_DETAILS_TOKENS', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors details tokens get error:', err);
        throw err;
      });
  },
  async getInvestorTokensWallets(
    { commit },
    { params: { issuerId, tokenId, userId } },
  ) {
    if (tokenId) {
      const { data: tokenPreferences } = await getTokenPreferences({
        issuerId,
        investorId: userId,
        tokenId: [tokenId],
      });
      let methodGot;
      if (tokenPreferences.length) {
        const { method } = tokenPreferences.find(
          tPreference => tPreference.controlPanelProfileId === String(userId)
            && tPreference.tokenId === tokenId
            && tPreference.issuerId === issuerId,
        );
        methodGot = { method };
      }
      return getTokensAndWallets(issuerId, userId, tokenId)
        .then(({ data }) => {
          commit('TOKENS_AND_WALLETS_SECTION', {
            ...data,
            // eslint-disable-next-line no-unneeded-ternary
            ...(methodGot ? methodGot : {}),
          });
          return { data };
        })
        .catch((err) => {
          this.$log.error('tokens and wallets section tokens get error:', err);
          throw err;
        });
    }
    return getTokensAndWallets(issuerId, userId)
      .then(({ data }) => {
        commit('TOKENS_AND_WALLETS_SECTION', data);
        return { data };
      })
      .catch((err) => {
        this.$log.error('tokens and wallets section tokens get error:', err);
        throw err;
      });
  },
  createUpdateTokenPreferences(
    { commit },
    { issuerId, investorId, tokenId, method, updatedByOperatorId, updatedBy },
  ) {
    return createUpdateTokenPreferences(
      issuerId,
      investorId,
      tokenId,
      method,
      updatedByOperatorId,
      updatedBy,
    )
      .then((res) => {
        commit('SET_TOKEN_PREFERENCES_DRIP', method);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.updateTokenPreferenceDripConfirmed'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors token preference get error:', err);
      });
  },
  updateInvestorTokensConfirmed(
    { commit },
    { issuerId, userId, tokenId, body },
  ) {
    return updateInvestorTokensAssigned(issuerId, userId, tokenId, body)
      .then((res) => {
        commit('SET_INVESTOR_DETAILS_TOKENS_BONUS', body);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.updateInvestorTokensConfirmed'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors details tokens get error:', err);
      });
  },

  async updateInvestorDetailGeneral({ commit }, { issuerId, userId, body }) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/details`;
    try {
      await axios.patch(url, body);
      commit('SET_INVESTOR_DETAILS_GENERAL', body);
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.updateInvestorDetailGeneral'),
        { root: true },
      );
      return body;
    } catch (err) {
      this.$log.error('Investors details general update error:', err);
    }
  },

  async updateTokenTransaction(
    { commit },
    { issuerId, tokenId, tokenTransactionId, body },
  ) {
    return updateTokenTransactionById({
      issuerId,
      tokenId,
      tokenTransactionId,
      body,
    }) // este es ejemplo
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.updateTokenTransaction'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('token transaction update error:', err);
      });
  },

  // eslint-disable-next-line no-empty-pattern
  async unlinkInvestor({ commit }, { issuerId, userId }) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/delinks`;
    try {
      const data = await axios.post(url, {
        headers: { 'Content-Type': 'application/json' },
      });
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.unlinkSecuritizeIdInvestor'),
        { root: true },
      );
      return data;
    } catch (err) {
      this.$log.error('Investors delinks error:', err);
      throw err;
    }
  },
  deleteTokenIssuanceById({ commit }, { issuerId, userId, issuancesId }) {
    return deleteTokenIssuanceById(issuerId, userId, issuancesId)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.deleteTokenIssuanceById'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors details delete issuances error:', err);
      });
  },

  createTokenIssuance({ commit }, { issuerId, userId, body }) {
    return createTokenIssuance(issuerId, userId, body)
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.createTokenIssuance'),
          { root: true },
        );
        return body;
      })
      .catch((err) => {
        this.$log.error('Investors details create issuances error:', err);
      });
  },
  editTokenIssuance({ commit }, { issuanceId, issuerId, userId, body }) {
    return editTokenIssuance(issuanceId, issuerId, userId, body)
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.editTokenIssuance'),
          { root: true },
        );
        return body;
      })
      .catch((err) => {
        this.$log.error('Edit token issuance error:', err);
      });
  },
  getInvestorInvestment({ commit }, { issuerId, userId, tokenId, roundId }) {
    return getInvestorInvestment(
      issuerId,
      userId,
      tokenId || undefined,
      roundId || undefined,
    )
      .then((data) => {
        commit('SET_INVESTOR_DETAILS_INVESTMENT', data);
        commit('SET_INVESTOR_TOKEN_CONFIG_DATA', data?.data?.token);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors details investment get error:', err);
      });
  },
  // eslint-disable-next-line no-unused-vars
  getInvestorInvestmentNoStateChange(
    { commit },
    { params: { issuerId, userId, roundId, tokenId } },
  ) {
    return getInvestorInvestment(
      issuerId,
      userId,
      tokenId || undefined,
      roundId || undefined,
    )
      .then((data) => {
        commit('SET_INVESTOR_TOKEN_CONFIG_DATA', data.data.token);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors details investment get error:', err);
      });
  },
  getInvestorInvestmentForDisplay(
    { commit },
    { params: { issuerId, userId, tokenId, roundId } },
  ) {
    return getInvestorInvestment(
      issuerId,
      userId,
      tokenId || undefined,
      roundId,
    )
      .then((data) => {
        commit(
          'SET_INVESTOR_INVESTMENT_REQUEST_FOR_DISPLAY',
          data.data.investmentRequestForDisplay,
        );
        commit('SET_INVESTOR_TOKEN_CONFIG_DATA', data?.data?.token);
        return data.data.investmentRequestForDisplay;
      })
      .catch((err) => {
        this.$log.error('Investors details investment get error:', err);
      });
  },
  async updateInvestorInvestmentById(
    { commit, dispatch },
    { issuerId, userId, roundId, body },
  ) {
    return updateInvestorInvestmentByUserId(issuerId, userId, roundId, body)
      .then(() => {
        commit('SET_INVESTOR_DETAILS_INVESTMENT', body);
        const investmentData = dispatch('getInvestorInvestment', {
          issuerId,
          userId,
          tokenId: body.tokenId,
          roundId,
        }).then(({ data }) => data);
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.updateInvestorInvestmentById'),
          { root: true },
        );
        return investmentData;
      })
      .catch((err) => {
        this.$log.error('Investors details investment update error:', err);
        throw err;
      });
  },

  createInvestorInvestment({ dispatch, commit }, { issuerId, userId, body }) {
    return createInvestorInvestment(issuerId, userId, body)
      .then(() => {
        dispatch('getInvestorInvestment', {
          issuerId,
          userId,
          tokenId: body.tokenId,
          roundId: body.roundId,
        });
        dispatch('getInvestorDetailsTokens', {
          issuerId,
          tokenId: body.tokenId,
          userId,
        });
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.createInvestorInvestment'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Investors details kyc update error:', err);
      });
  },
  getInvestorDocuments(ctx, { params }) {
    const { issuerId, userId } = params;
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/documents`;
    try {
      return axios.get(url);
    } catch (err) {
      this.$log.error('Investors details documents get error:', err);
    }
  },
  downloadUserDocumentById(ctx, { issuerId, userId, userDocumentId, origin }) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/documents/${userDocumentId}`;
    try {
      return axios.get(url, { params: { origin } });
    } catch (err) {
      this.$log.error('Download file error:', err);
    }
  },

  async addInvestorDetailDocument(
    { commit },
    {
      issuerId,
      userId,
      fileKey,
      documentFace,
      type,
      showToInvestor,
      documentTitle,
      tokenId,
    },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/documents`;
    try {
      const res = await axios.post(url, {
        fileKey,
        documentFace,
        type,
        showToInvestor,
        documentTitle,
        tokenId,
      });
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.addInvestorDetailDocument'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Document add error:', err);
    }
  },

  async deleteInvestorDetailDocument(
    { commit },
    { issuerId, userId, userDocumentId },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/documents/${userDocumentId}`;
    try {
      const res = await axios.delete(url);
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.deleteInvestorDetailDocument'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Download file error:', err);
    }
  },

  async getInvestorKYCStatusById({ commit }, { issuerId, userId, params }) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/kyc`;
    try {
      const res = await axios.get(url, { params });
      const { data } = res;
      commit('SET_INVESTOR_DETAILS_KYC', data);
      return data;
    } catch (err) {
      this.$log.error('Investors details kyc get error:', err);
      throw err;
    }
  },

  addInvestorDetailTokenWallet({ commit }, { issuerId, userId, body }) {
    return addTokenWallet(issuerId, userId, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.addInvestorDetailTokenWallet'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Token wallet add error:', err);
        throw err;
      });
  },

  editInvestorDetailTokenWallet(
    { commit },
    { issuerId, userId, tokenWalletId, body },
  ) {
    return editTokenWallet(issuerId, userId, tokenWalletId, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.editInvestorDetailTokenWallet'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Token wallet update error:', err);
      });
  },

  deleteInvestorDetailTokenWallet(
    { commit },
    { issuerId, userId, tokenWalletId },
  ) {
    return deleteTokenWallet(issuerId, userId, tokenWalletId)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.deleteInvestorDetailTokenWallet'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Token wallet delete error:', err);
      });
  },

  getInvestorKYCLastReportById(ctx, { issuerId, userId }) {
    const options = {
      headers: {
        accept: 'application/pdf',
      },
      responseType: 'blob',
    };
    return getInvestorKYCLastReportById(issuerId, userId, options)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(
          blob,
          `${Cookie.get('stockExchangeRegulation')}-report.pdf`,
        );
        return data;
      })
      .catch((err) => {
        this.$log.error('Get KYC report error:', err);
      });
  },

  checkInvestorDetailPerformManualKYC({ commit }, { issuerId, userId }) {
    return performManualKycCheck(issuerId, userId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t(
            'store.toaster.investors.checkInvestorDetailPerformManualKYC',
            [Cookie.get('stockExchangeRegulation')],
          ),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Check investor detail perform manual KYC error:', err);
      });
  },

  sendAccreditationEmail({ commit }, { issuerId, userId }) {
    return sendAccreditationEmail(issuerId, userId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.sendAccreditationEmail'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error(
          'Investors details send accreditation email error:',
          err,
        );
      });
  },

  async updateKYCDetails(
    { dispatch, commit },
    { issuerId, userId, body, params },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/kyc`;
    try {
      const res = await axios.patch(url, body, { params });
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.updateKYCDetails', [
          Cookie.get('stockExchangeRegulation'),
        ]),
        { root: true },
      );
      dispatch('getInvestorKYCStatusById', { issuerId, userId, params });
      return res;
    } catch (err) {
      this.$log.error('Investors details kyc update error:', err);
      throw err;
    }
  },

  async addInvestorDetailTransaction(
    { dispatch, commit },
    { issuerId, userId, roundId, body },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/deposit-transactions`;
    if (body.usdWorth) body.usdWorth = Number(body.usdWorth);
    body.amount = Number(body.amount);
    body.currencyId = Number.parseInt(body.currencyId);
    try {
      const res = await axios.post(url, body);
      dispatch('getInvestorInvestment', {
        issuerId,
        userId,
        tokenId: body.tokenId,
        roundId,
      });
      dispatch('getInvestorDetailsTokens', {
        issuerId,
        tokenId: body.tokenId,
        userId,
      });
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.addInvestorDetailTransaction'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Transaction add error:', err);
      throw err;
    }
  },

  async updateInvestorDetailTransaction(
    { commit },
    { issuerId, userId, transaction: { id, ...data } },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/deposit-transactions/${id}`;
    const body = { ...data };
    if (body.transactionTime) body.transactionTime = parseDateForServer(body.transactionTime);
    if (body.amount) body.amount = Number.parseInt(body.amount);
    try {
      const res = await axios.patch(url, body);
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.updateInvestorDetailTransaction'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Transaction update error:', err);
      throw err;
    }
  },

  bulkAccreditationEmail(ctx, { issuerId, body }) {
    return sendAccreditationEmails(issuerId, body).then(res => res.data);
  },
  bulkInviteSecIdEmail(ctx, { issuerId, body }) {
    return sendInviteSecIdEmails(issuerId, body).then(res => res.data);
  },
  bulkSecIDReverseSync({ commit }, { issuerId, body }) {
    return reverseSync(issuerId, body).then((res) => {
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.bulkSecIDReverseSync'),
        { root: true },
      );
      return res.data;
    });
  },
  bulkInviteEmail(ctx, { issuerId, body }) {
    return sendInviteEmails(issuerId, body).then(res => res.data);
  },
  bulkResetPassword(ctx, { issuerId, body }) {
    return resetPasswords(issuerId, body).then(res => res.data);
  },
  async bulkRunIssuance(ctx, { issuerId, body }) {
    const url = `${basePath}issuers/${issuerId}/investors-bulk/run-bulk-issuance`;
    try {
      const res = await axios.post(url, body);
      return { data: res.data };
    } catch (err) {
      this.$log.error('Run Bulk Issuance error:', err);
    }
  },
  bulkSendFundingInstructionsEmail(ctx, { issuerId, body }) {
    return sendFundingInstructionsEmails(issuerId, body, {
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.data);
  },
  bulkSendDocuSignEmail(ctx, { issuerId, body }) {
    return sendDocuSignEmail(issuerId, body).then(res => res.data);
  },
  async deleteInvestorDetailTransaction(
    { commit },
    { issuerId, userId, depositTransactionId, tokenId, depositWalletId },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/deposit-transactions/${depositTransactionId}`;
    try {
      const res = await axios.delete(url, {
        data: {
          tokenId,
          depositWalletId,
        },
      });
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.deleteInvestorDetailTransaction'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Transaction delete error:', err);
      throw err;
    }
  },

  getPartners(ctx, issuerId) {
    return getPartners(issuerId).catch((err) => {
      this.$log.error('Partners get error:', err);
    });
  },

  async updateDepositWalletById(
    { commit },
    { issuerId, userId, walletId, body },
  ) {
    const url = `${basePath}issuers/${issuerId}/investors/${userId}/deposit-wallets/${walletId}`;
    try {
      const res = await axios.patch(url, body);
      commit(
        'global/CALL_TOASTER',
        i18n.t('store.toaster.investors.updateDepositWalletById'),
        { root: true },
      );
      return res;
    } catch (err) {
      this.$log.error('Deposit wallet update error:', err);
      throw err;
    }
  },
  moveBonus({ commit }, { issuerId, tokenId, userId }) {
    return moveBonus(issuerId, userId, { tokenId })
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.moveBonus'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Token bonus move error:', err);
        throw err;
      });
  },
  getAffiliateRecords(ctx, { params }) {
    const args = getArgumentsForApi(holderAffiliateRecordsArguments, params);
    return getHolderAffiliateRecords(...args)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Holder Affilite Records get error:', err);
        return err;
      });
  },
  sendFundingInstructionsEmail({ commit }, { issuerId, userId, tokenId }) {
    return sendFundingInstructionsEmail(issuerId, userId, tokenId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.fundingInstructionsEmailSent'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Funding instructions email send error:', err);
        throw err;
      });
  },
  sendAgreementEmail({ commit }, { issuerId, userId, tokenId }) {
    return sendAgreementEmail(issuerId, tokenId, userId, {
      data: {},
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.agreementEmailSent'),
          { root: true },
        );
      })
      .catch((err) => {
        this.$log.error('Funding instructions email send error:', err);
        throw err;
      });
  },
  getLegalSignersList(ctx, { params: { issuerId, userId } }) {
    return getLegalSigners(issuerId, userId)
      .then(res => res)
      .catch((err) => {
        this.$log.error('Get legal signers error:', err);
        throw err;
      });
  },
  deleteLegalSignerById(
    { commit },
    { params: { issuerId, userId, legalSignerId } },
  ) {
    return deleteLegalSigner(issuerId, userId, legalSignerId)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.deleteLegalSigner'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Legal Signer delete error:', err);
        throw err;
      });
  },
  createLegalSigner({ commit }, { issuerId, userId, body }) {
    return createLegalSigner(issuerId, userId, body)
      .then((res) => {
        commit(
          'global/CALL_TOASTER',
          i18n.t('store.toaster.investors.addLegalSigner'),
          { root: true },
        );
        return res;
      })
      .catch((err) => {
        this.$log.error('Legal Signer add error:', err);
        throw err;
      });
  },
  getLegalSignerById(
    { commit },
    { params: { issuerId, userId, legalSignerId, origin } },
  ) {
    return getLegalSignerById(issuerId, userId, legalSignerId, origin)
      .then(({ data }) => {
        commit('SET_LEGAL_SIGNER_GENERAL_INFO', data);
        return data.data;
      })
      .catch((err) => {
        this.$log.error('Get legal signer by id error:', err);
        throw err;
      });
  },
  getLegalSignerDocumentById(
    ctx,
    { issuerId, userId, legalSignerId, legalSignerDocumentId, origin },
  ) {
    return getLegalSignerDocumentById(
      issuerId,
      userId,
      legalSignerId,
      legalSignerDocumentId,
      origin,
    )
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Get legal signer document by id error:', err);
        throw err;
      });
  },
  updateLegalSignerById(
    { commit },
    { issuerId, userId, legalSignerId, legalSigner },
  ) {
    return updateLegalSignerById(issuerId, userId, legalSignerId, legalSigner)
      .then(({ data }) => {
        commit('SET_LEGAL_SIGNER_GENERAL_INFO', data.data);
        commit('global/CALL_TOASTER', 'Legal Signer updated successfully', {
          root: true,
        });
        return data;
      })
      .catch((err) => {
        this.$log.error('Legal Signer update error:', err);
        return err;
      });
  },
  deleteLegalSignerDocumentById(
    { commit },
    { issuerId, userId, legalSignerId, legalSignerDocumentId },
  ) {
    return deleteLegalSignerDocumentById(
      issuerId,
      userId,
      legalSignerId,
      legalSignerDocumentId,
    )
      .then((res) => {
        commit('global/CALL_TOASTER', 'Legal Signer document is deleted', {
          root: true,
        });
        return res;
      })
      .catch((err) => {
        this.$log.error('Legal Signer document delete error:', err);
      });
  },
  createLegalSignerDocument(
    { commit },
    { issuerId, userId, legalSignerId, document },
  ) {
    return createLegalSignerDocument(issuerId, userId, legalSignerId, document)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Legal Signer Document is added', {
          root: true,
        });
        return data;
      })
      .catch((err) => {
        this.$log.error('Legal Signer document add error:', err.message);
      });
  },
};
