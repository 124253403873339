import FileSaver from 'file-saver';
import {
  getDocumentsByInvestorId,
  getInvestorDocumentById,
  getInvestorDocumentBlob,
  createInvestorDocument,
  deleteInvestorDocumentById,
  getLegalSignerById,
  updateLegalSignerByInvestorId,
  deleteLegalSignerByInvestorId,
  addLegalSignerDocument,
  deleteLegalSignerDocumentById,
  addLegalSigner,
  getLegalSignersByInvestorId,
  getLegalSignerDocumentById,
  getFilteredInvestors,
  getCsvInvestors,
  createInvestor,
  updateSecuritizeIdInvestorById,
  deleteInvestorById,
  removeSecuritizeIdInvestor2fa,
  runInvestorVerificationCheck,
  getVerificationLinkData,
  getSecuritizeIdInvestorById,
  investorResetPassword,
  updateInvestorVerificationStatus,
  createInvestorConfidentialDocument,
  deleteInvestorConfidentialDocumentById,
  getConfidentialDocumentsByInvestorId,
  getInvestorConfidentialDocumentById,
  getWalletsByInvestorId,
  createInvestorWallet,
  removeInvestorWallet,
  getInvestorKycHistory,
  updateSecuritizeIdInvestorDocuments,
  updateSecuritizeIdInvestorConfidentialDocuments,
  updateSecuritizeIdInvestorLegalSignerDocumentsStatus,
  getFilteredTranslations,
  updateTranslationByKeyAndSystem,
  importTranslationsCsv,
  getSecuritizeIdLabels,
  deleteSecuritizeIdLabel,
  createSecuritizeIdLabel,
  updateSecuritizeIdInvestorsLabels,
  getSecuritizeIdBlacklistedWallets,
  getSecuritizeIdWhitelistedWallets,
  removeWalletFromBlackList,
  removeWalletFromWhitelist,
  addWalletToBlackList,
  addWalletToWhitelist,
  getSecuritizeIdAuthorizedAccount,
  getSecuritizeIdAuthorizedByAccounts,
  sendSecuritizeIdAuthorizedAccountRequest,
  removeSecuritizeIdAuthorizedAccount,
  transferOwnershipOfSecuritizeIdAuthorizedAccount,
  changeAuthorizedAccoutnRole,
  postLinkVerification,
  getDocumentOptions,
  getKeyPartyDocumentOptions,
} from '~/utilities/external-api/apis/securitizeIdCpApi';
import { documents as investorDocuments, docTypeTitleMap } from './options';

const useStaticDocuments = process.env.VUE_APP_STATIC_DOCUMENTS === 'true';

function documentCategoryItemMapper(document) {
  return { value: { docCategory: 'entity', docType: document.documentType }, text: docTypeTitleMap[document.documentType] ?? document.documentType };
}

export default {
  createSecuritizeIdInvestor({ commit }, body) {
    return createInvestor(body)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Securitize ID investor is added', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investor add error:', err);
      });
  },
  getSecuritizeIdInvestorsList(ctx, { params }) {
    return getFilteredInvestors(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID investors get error:', err);
      });
  },
  getSecuritizeIdInvestorKycHistory(ctx, { params }) {
    return getInvestorKycHistory(params.investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID investor kyc history get error:', err);
      });
  },
  getSecuritizeIdInvestorsListCsv(ctx, { params, fileName }) {
    return getCsvInvestors(params)
      .then(({ data }) => {
        const blob = new Blob([data]);
        FileSaver.saveAs(blob, `${fileName}.csv`);
        return data;
      })
      .catch((err) => {
        this.$log.error('Investors export error:', err);
      });
  },
  getSecuritizeIdInvestorByInvestorId({ commit }, { investorId }) {
    return getSecuritizeIdInvestorById(investorId)
      .then((res) => {
        commit('SET_SECURITIZE_ID_INVESTOR_INFO', res.data);
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investors get error:', err);
      });
  },
  getSecuritizeIdInvestor(_, { investorId }) {
    return getSecuritizeIdInvestorById(investorId)
      .then(res => res.data)
      .catch((err) => {
        this.$log.error('Securitize ID investors update error:', err);
      });
  },
  updateSecuritizeIdInvestorByInvestorId({ commit }, { investorId, info }) {
    return updateSecuritizeIdInvestorById(investorId, info)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Securitize ID investor info is updated', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investors update error:', err);
        return Promise.reject(err);
      });
  },
  securitizeIdInvestorResetPassword({ commit }, { investorId }) {
    return investorResetPassword(investorId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Password reset instructions sent via email successfully', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Unable to reset password, got error:', err);
      });
  },
  removeSecuritizeIdInvestor2fa({ commit }, { investorId }) {
    return removeSecuritizeIdInvestor2fa(investorId)
      .then((res) => {
        commit('global/CALL_TOASTER', '2FA has been successfully disabled', { root: true });
        commit('SET_SECURITIZE_ID_TFA', false);
        return res;
      })
      .catch((err) => {
        this.$log.error('Unable to disabled 2FA, got error:', err);
        throw err;
      });
  },
  updateSecuritizeIdInvestorVerificationStatus({ commit }, { investorId, newStatus, newSubStatus, newVerificationLevel, requestedUpdates, errors }) {
    return updateInvestorVerificationStatus(investorId, { newStatus, newSubStatus, newVerificationLevel, requestedUpdates, errors })
      .then((res) => {
        commit('global/CALL_TOASTER', 'investor verification status successfully changed', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Unable to change investor verification status:', err);
      });
  },
  performSecuritizeIdInvestorManualCheck({ commit }, { investorId }) {
    return runInvestorVerificationCheck(investorId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'investor verification check requested successfully', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Unable to change investor verification status:', err);
      });
  },
  getInvestorVerificationLinkData(_, { investorId, applicantId }) {
    return getVerificationLinkData(investorId, applicantId)
      .then(res => res?.data?.data)
      .catch((err) => {
        this.$log.error('Unable To Get Investor Link:', err);
      });
  },
  linkInvestorVerification({ commit }, { investorId, applicantId }) {
    return postLinkVerification(investorId, applicantId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Investor Was Successfully Linked', { root: true });
        return res;
})
      .catch((err) => {
        this.$log.error('Unable To Get Investor Link:', err);
      });
  },
  deleteSecuritizeIdInvestor({ commit }, { investorId }) {
    return deleteInvestorById(investorId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Investor was successfully deleted', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize Id delete Investor got error:', err);
      });
  },

  changeDetailsEditableBlock({ commit }, blockName) {
    commit('CHANGE_DETAILS_EDITABLE_BLOCK', blockName);
  },

  getSecuritizeIdInvestorDocuments(ctx, { params: { investorId } }) {
    return getDocumentsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id Investor Documents get error:', err);
        return err;
      });
  },
  getSecuritizeIdInvestorConfidentialDocuments(ctx, { params: { investorId } }) {
    return getConfidentialDocumentsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id Investor confidential documents get error:', err);
        return err;
      });
  },
  downloadSecuritizeIdInvestorDocumentById(ctx, { investorId, documentId, isConfidential }) {
    const downloadRequest = isConfidential ? getInvestorConfidentialDocumentById : getInvestorDocumentById;
    return downloadRequest(investorId, documentId)
      .then(({ data }) => data).catch((err) => {
        this.$log.error('Download file error:', err);
      });
  },
  downloadSecuritizeIdDocumentBlobByKey(ctx, { investorId, key }) {
    return getInvestorDocumentBlob({ investorId, key })
      .then(({ data }) => data).catch((err) => {
        this.$log.error('Download investor document blob error:', err);
      });
  },
  deleteSecuritizeIdInvestorDocumentById({ commit }, { investorId, documentId, isConfidential }) {
    const deleteRequest = isConfidential ? deleteInvestorConfidentialDocumentById : deleteInvestorDocumentById;
    return deleteRequest(investorId, documentId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Document is deleted', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Document delete error:', err);
      });
  },

  getInvestorWallets(ctx, { params: { investorId } }) {
    return getWalletsByInvestorId(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Investors wallets get error:', err);
      });
  },

  addInvestorWallet({ commit }, { investorId, wallet }) {
    return createInvestorWallet(investorId, wallet)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Wallet was created', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors wallets create error:', err);
      });
  },

  deleteInvestorWallet({ commit }, { investorId, walletId }) {
    return removeInvestorWallet(investorId, walletId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Wallet was removed', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Investors wallets remove error:', err);
      });
  },

  addSecuritizeIdInvestorDocument({ commit }, {
    investorId, fileKey, fileType, side, docType = 'other', docCategory = 'other',
  }) {
    return createInvestorDocument(investorId, {
      fileKey, fileType, side, docCategory, docType,
    })
      .then((res) => {
        commit('global/CALL_TOASTER', 'Document is added', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Document add error:', err);
      });
  },
  addSecuritizeIdInvestorConfidentialDocument({ commit }, {
    investorId, fileKey, fileType, docType,
  }) {
    return createInvestorConfidentialDocument(investorId, { fileKey, fileType, docType })
      .then((res) => {
        commit('global/CALL_TOASTER', 'Document is added', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Document add error:', err);
      });
  },
  getSecuritizeIdLegalSignersList({ commit }, { params: { investorId, ...queryOptions } }) {
    // EXTERNAL GW //
    return getLegalSignersByInvestorId(investorId, queryOptions)
      .then((data) => {
        commit('SECURITIZE_ID_LEGAL_SIGNERS_LIST', data);
        return data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer get list error:', err);
        return err;
      });
  },
  // createSecuritizeIdLegalSigner({ commit, dispatch }, { investorId, model: queryOptions }) {
  //   return createLegalSigner(investorId, queryOptions)
  //     .then((data) => {
  //       dispatch('getSecuritizeIdLegalSignersList', { params: { investorId } });
  //       commit('CREATE_SECURITIZE_ID_LEGAL_SIGNER', data);
  //       return data;
  //     })
  //     .catch((err) => {
  //       this.$log.error('Securitize Id Create Legal Signer POST error:', err);
  //       return err;
  //     });
  // },
  createSecuritizeIdLegalSigner({ commit }, { investorId, legalSigner }) {
    // EXTERNAL GW //
    return addLegalSigner(investorId, legalSigner)
      .then((data) => {
        commit('global/CALL_TOASTER', 'Legal Signer successfully added', { root: true });
        // dispatch('getSecuritizeIdLegalSignersList', { params: { investorId } });
        return data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Create Legal Signer POST error:', err);
        return err;
      });
  },
  getSecuritizeIdLegalSignerById({ commit }, { params: { investorId, legalSignerId } }) {
    // EXTERNAL GW //
    return getLegalSignerById(investorId, legalSignerId)
      .then(({ data }) => {
        commit('SECURITIZE_ID_LEGAL_SIGNER_BY_ID', data.data);
        return data.data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer by ID GET error:', err);
        return err;
      });
  },
  addSecuritizeIdLegalSignerDocument({ commit }, {
    investorId, legalSignerId, document,
  }) {
    // EXTERNAL GW //
    return addLegalSignerDocument(investorId, legalSignerId, document)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Document is added', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Document add error:', err.message);
      });
  },
  deleteSecuritizeIdLegalSignerByInvestorId({ commit }, { params: { investorId, legalSignerId } }) {
    // EXTERNAL GW //
    return deleteLegalSignerByInvestorId(investorId, legalSignerId)
      .then(({ data }) => {
        commit('global/CALL_TOASTER', 'Legal Signer successfully deleted', { root: true });
        return data.data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer by ID DELETE error:', err);
        return err;
      });
  },
  updateSecuritizeIdLegalSignerById({ commit }, { investorId, legalSignerId, legalSigner }) {
    // EXTERNAL GW //
    return updateLegalSignerByInvestorId(investorId, legalSignerId, legalSigner)
      .then(({ data }) => {
        commit('SECURITIZE_ID_LEGAL_SIGNER_BY_ID', data.data);
        commit('global/CALL_TOASTER', 'Legal Signer updated successfully', { root: true });
        return data;
      })
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer by ID UPDATE error:', err);
        return err;
      });
  },
  deleteSecuritizeIdLegalSignerDocumentById({ commit }, { investorId, legalSignerId, documentId }) {
    // EXTERNAL GW //
    return deleteLegalSignerDocumentById(investorId, legalSignerId, documentId)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Document is deleted', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Document delete error:', err);
      });
  },
  getSecuritizeILegalSignerDocumentById(storeObj, { investorId, legalSignerId, documentId }) {
    // EXTERNAL GW //
    return getLegalSignerDocumentById(investorId, legalSignerId, documentId)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Securitize Id Legal Signer Document by ID GET error:', err);
        return err;
      });
  },
  updateInvestorDocuments({ commit }, { investorId, documents }) {
    return updateSecuritizeIdInvestorDocuments(investorId, documents)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Documents successfully updated', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investors documents update error:', err);
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating investor documents', { root: true });
      });
  },
  updateInvestorConfidentialDocuments({ commit }, { investorId, documents }) {
    return updateSecuritizeIdInvestorConfidentialDocuments(investorId, documents)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Confidential documents successfully updated', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID investors confidential documents update error:', err);
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating investor confidential documents', { root: true });
      });
  },
  updateInvestorLegalSignerDocumentsStatus({ commit }, { investorId, legalSignerId, documents }) {
    return updateSecuritizeIdInvestorLegalSignerDocumentsStatus(investorId, legalSignerId, documents)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Documents successfully updated', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID documents update error:', err);
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating documents', { root: true });
      });
  },
  getSecuritizeIdTranslationsList(ctx, { params }) {
    const { q, 'order-direction': order, 'order-field': sort, ...other } = params;
    return getFilteredTranslations({ search: q, order, sort, ...other })
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize ID translations get error:', err);
      });
  },
  updateTranslation({ commit }, { key, system, values }) {
    return updateTranslationByKeyAndSystem(key, system, values)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Translation successfully updated', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID Translation update error:', err);
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while updating Translation', { root: true });
      });
  },
  importTranslations({ commit }, { data }) {
    return importTranslationsCsv(data)
      .then((res) => {
        commit('global/CALL_TOASTER', 'Translations successfully imported', { root: true });
        return res;
      })
      .catch((err) => {
        this.$log.error('Securitize ID Translations import error:', err);
        commit('global/CALL_ERROR_TOASTER', 'An error occurred while importing translations', { root: true });
      });
  },
  getLabels(ctx, params) {
    return getSecuritizeIdLabels(params)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id labels get error:', err);
        return err;
      });
  },
  createLabel(ctx, label) {
    return createSecuritizeIdLabel({ label })
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id create label error:', err);
        return err;
      });
  },
  deleteLabel(ctx, labelId) {
    return deleteSecuritizeIdLabel(labelId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id delete label error:', err);
        return err;
      });
  },
  updateInvestorsLabels(ctx, body) {
    return updateSecuritizeIdInvestorsLabels(body)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id update investors label error:', err);
        return err;
      });
  },
  getBlacklistedWallets(ctx, { params }) {
    return getSecuritizeIdBlacklistedWallets(params)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Securitize Id get blacklisted wallets error:', err);
        return err;
      });
  },
  getWhitelistedWallets(ctx, { params }) {
    return getSecuritizeIdWhitelistedWallets(params)
      .then(({ data }) => data)
      .catch((err) => {
        this.$log.error('Securitize Id get whitelisted wallets error:', err);
        return err;
      });
  },
  removeBlacklistedWallet(ctx, walletId) {
    return removeWalletFromBlackList(walletId)
      .catch((err) => {
        this.$log.error('Securitize Id remove wallet from blacklist error:', err);
        return err;
      });
  },
  removeWhitelistedWallet(ctx, walletId) {
    return removeWalletFromWhitelist(walletId)
      .catch((err) => {
        this.$log.error('Securitize Id remove wallet from whitelist error:', err);
        return err;
      });
  },

  blacklistWallet(ctx, wallet) {
    return addWalletToBlackList(wallet)
      .catch((err) => {
        this.$log.error('Securitize Id blacklist wallet error:', err);
        throw err;
      });
  },
  whitelistWallet(ctx, wallet) {
    return addWalletToWhitelist(wallet)
      .catch((err) => {
        this.$log.error('Securitize Id whitelist wallet error:', err);
        throw err;
      });
  },
  updateSecuritizeIdAssignedAttorneyOperator({ commit }, { investorId, operatorId }) {
    return updateSecuritizeIdInvestorById(investorId, { assignedAttorneyOperatorId: operatorId })
    .then((res) => {
      commit('global/CALL_TOASTER', 'Securitize ID investor info is updated', { root: true });
      return res;
    })
    .catch((err) => {
      this.$log.error('Securitize ID investors update error:', err);
      return Promise.reject(err);
    });
  },
  // uploadSecuritizeIdLegalSignerDocuments(ctx, { investorId, fileKey }) {
  //   return uploadLegalSignerDocuments(investorId, { fileKey })
  //     .then(data => data)
  //     .catch((err) => {
  //       this.$log.error('Securitize Id upload Legal Signer Document by ID POST error:', err);
  //       return err;
  //     });
  // },
  // getSecuritizeIdLegalSignerDocuments(storeObj, { params: { investorId, legalSignerId } }) {
  //   return getLegalSignerDocuments(investorId, legalSignerId)
  //     .then(data => data)
  //     .catch((err) => {
  //       this.$log.error('Securitize Id Legal Signer Documents list POST error:', err);
  //       return err;
  //     });
  // },
  getSecuritizeIdAuthorizedAccount(ctx, { params: { investorId } }) {
    return getSecuritizeIdAuthorizedAccount(investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id Authorized Accounts list GET error:', err);
        return err;
      });
  },
  getSecuritizeIdAuthorizedByAccounts(ctx, { investorId }) {
    return getSecuritizeIdAuthorizedByAccounts(investorId)
      .catch((err) => {
        this.$log.error('Securitize Id Authorized By Accounts list GET error:', err);
        return err;
      });
  },
  sendSecuritizeIdAuthorizedAccountRequest(ctx, { email, role, investorId }) {
    return sendSecuritizeIdAuthorizedAccountRequest({ email, role, investorId })
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id send Authorized Account request POST error:', err);
        return err;
      });
  },
  changeAuthorizedAccoutnRole(ctx, { investorId, email, inviteeUserId, newRole }) {
    return changeAuthorizedAccoutnRole(investorId, email, inviteeUserId, newRole)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id change Authorized Account role request POST error:', err);
        return err;
      });
  },
  removeSecuritizeIdAuthorizedAccount(ctx, { email, investorId }) {
    return removeSecuritizeIdAuthorizedAccount(email, investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id remove Authorized Account DELETE error:', err);
        return err;
      });
  },
  transferOwnershipOfSecuritizeIdAuthorizedAccount(ctx, { newOwnerUserId, investorId }) {
    return transferOwnershipOfSecuritizeIdAuthorizedAccount(newOwnerUserId, investorId)
      .then(data => data)
      .catch((err) => {
        this.$log.error('Securitize Id transfer ownership of authorized account PUT error:', err);
        return err;
      });
  },
  // TODO: only used for retrieving entity documents at the moment. Update when individual investor type is supported
  getDocumentCategories(ctx, { investorFacing } = {}) {
    const documentCategories = investorDocuments.categories;

    if (useStaticDocuments) {
      return Promise.resolve(documentCategories);
    }

    return getDocumentOptions({ investorType: 'entity', investorFacing }).then(({ data: response }) => {
      const entityDocuments = response.data.map(documentCategoryItemMapper);

      return {
        ...documentCategories,
        entityDocumentTypes: entityDocuments,
        entityConfidentialDocumentTypes: entityDocuments,
      };
    }).catch((err) => {
        this.$log.error('Securitize Id get document options GET error:', err);
        throw err;
    });
  },
  getKeyPartyDocumentCategories(ctx, { investorFacing } = {}) {
    const documentCategories = investorDocuments.categories;

    if (useStaticDocuments) {
      return Promise.resolve(documentCategories);
    }

    return getKeyPartyDocumentOptions({ keyPartyType: 'entity', investorFacing }).then(({ data: response }) => {
      const entityDocumentTypes = response.data.map(documentCategoryItemMapper);

      return {
        ...documentCategories,
        entityDocumentTypes,
      };
    }).catch((err) => {
      this.$log.error('Securitize Id get document options GET error:', err);
      throw err;
    });
  },
};
